import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setBetError } from '../slices/popup.slices';

export const sentTicketThunk = createAsyncThunk(
  'sentTicket/sentTicketThunk',
  async function (ticket, { dispatch }) {
    try {
      const { bet, duration, raceId, amount } = ticket;

      const resp = await axios.post(`place-bet`, {
        bet,
        duration,
        raceId,
        amount: `${amount}`,
      });

      return { resp, ticket };
    } catch (error) {
      dispatch(setBetError());
    }
  },
);

export const sentTicketExtraReducer = (builder) => {
  builder
    .addCase(sentTicketThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(sentTicketThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.payload = payload;
      state.balance = payload.resp.balance;
    })
    .addCase(sentTicketThunk.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error.message;
    });
};
