import React from 'react';
import s from './UserInfo.module.scss';
import { useSelector } from 'react-redux';
import { selectCurrency, selectUserBalance } from '../../../../redux/slices/balance.slices';
import { selectNewBalance } from '../../../../redux/slices/sentTicket.slices';
import { selectUserId } from '../../../../redux/slices/configs.slices';

function UserInfo() {
  const userBalance = useSelector(selectUserBalance);
  const newUserBalance = useSelector(selectNewBalance);
  const currency = useSelector(selectCurrency);
  const userId = useSelector(selectUserId);

  return (
    <div className={s.headerMobileInfoContainer}>
      <div className={s.headerMobileBalance}>
        <div>{newUserBalance ? newUserBalance : userBalance}</div>
        <div className={s.headerMobileBalance__currency}>{currency ? currency : ''}</div>
      </div>
      <div className={s.headerMobileUserId}>
        ID:<span>{userId}</span>
      </div>
    </div>
  );
}

export default UserInfo;
