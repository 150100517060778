import CoefficientTableSection from '../components/Mobile/BurgerMenu/HowToPlayMobile/HowToPlayTabs/Tabs/CoefficientTableSection/CoefficientTableSection';
import GameRules from '../components/Mobile/BurgerMenu/HowToPlayMobile/HowToPlayTabs/Tabs/GameRules/GameRules';
import { HOWTOPLAY_TABS } from '../constants/game.constatnt';

const { GAME_RULES, COEFFICIENT_TABLE } = HOWTOPLAY_TABS;

export const HOWTOPLAY_TABS_CONFIGS = {
  [GAME_RULES]: {
    Component: GameRules,
  },
  [COEFFICIENT_TABLE]: {
    Component: CoefficientTableSection,
  },
};
