import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getConfigsThunk = createAsyncThunk(
  'configs/getConfigsThunk',
  async function (duration) {
    const response = await axios.get(`get-config`, {
      params: {
        duration: duration,
      },
    });
    return response;
  },
);

export const configsExtraReducer = (builder) => {
  builder
    .addCase(getConfigsThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getConfigsThunk.fulfilled, (_, { payload }) => ({
      isLoading: false,
      ...payload,
    }))
    .addCase(getConfigsThunk.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error.message;
    });
};
