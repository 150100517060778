import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { HEADER_BUTTONS_CONFIGS } from '../../../../configs/headerButtons.configs';
import { useTranslation } from '../../../../context/LanguageContext';
import { setHowToPlayActive } from '../../../../redux/slices/popup.slices';
import Button from '../../UI/Button/Button';

function HowToPlayButton({ className, button }) {
  const dispatch = useDispatch();
  const t = useTranslation();
  const isMobile = useMediaQuery({
    query: '(max-width: 1020px)',
  });

  const icon = HEADER_BUTTONS_CONFIGS[button]?.icon;
  const text = HEADER_BUTTONS_CONFIGS[button]?.text;

  return (
    <Button
      className={className}
      icon={icon}
      text={isMobile ? '' : t(text)}
      onClick={() => dispatch(setHowToPlayActive())}
    />
  );
}

export default memo(HowToPlayButton);
