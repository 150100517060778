import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getTimeThunk = createAsyncThunk('time/getTimeThunk', async function (duration) {
  const response = await axios.get(`get-time`, {
    params: {
      duration: duration,
    },
  });
  return response;
});

export const timeExtraReducer = (builder) => {
  builder
    .addCase(getTimeThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getTimeThunk.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.time = payload.time;
      state.raceId = payload.raceId;
    })
    .addCase(getTimeThunk.rejected, (state, { error }) => {
      state.isLoading = false;
      state.error = error.message;
    });
};
