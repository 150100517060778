import axios from 'axios';
import { setSessionErrorActive } from '../redux/slices/popup.slices';
import { dispatch, store } from '../redux/store';

export function setupAxios() {
  const cancels = new Map();
  const token = store.getState().token?.token;

  axios.interceptors.request.use(
    (req) => {
      const { url, method } = req;

      req.headers.token = token ? token : 'test-frontend-token';

      if (!req.baseURL) {
        req.baseURL = `${process.env.REACT_APP_API_URL}`;
      }

      if (method.toLowerCase() === 'get') {
        const currentCancel = cancels.get(`${method}:${url}`);

        if (currentCancel) {
          currentCancel();
        }

        req.cancelToken = new axios.CancelToken((cancel) => {
          cancels.set(`${method}:${url}`, cancel);
        });
      }

      return req;
    },
    (err) => Promise.reject(err),
  );

  axios.interceptors.response.use(
    (response) => {
      const { url, method } = response.config;
      if (method.toLowerCase() === 'get') {
        cancels.delete(`${method}:${url}`);
      }
      return response.data.data;
    },
    (error) => {
      if (axios.isCancel(error)) {
        return new Promise(() => {});
      }

      dispatch(setSessionErrorActive('The session was expired'));

      if (error.response?.config) {
        const { url, method } = error.response.config;
        if (method.toLowerCase() === 'get') {
          cancels.delete(`${method}:${url}`);
        }
      }

      return Promise.reject(error);
    },
  );
}
