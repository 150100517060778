export const GAME_TABS = {
  COEFFICIENT: 'Coefficient',
  HISTORY: 'History',
  JACKPOT: 'Jackpot',
  LEADERBOARD: 'Leaderboard',
};

export const HISTORY_TABS = {
  DRAW_HISTORY: 'Draw History',
  MY_HISTORY: 'My History',
};

export const MOBILE_FOOTER_TABS = {
  HOME: 'Home',
  CURRENT: 'Current',
  HISTORY: 'History',
  JACKPOT: 'Jackpot',
  LEADERBOARD: 'Leaderboard',
};
