import React from 'react';
import s from './GameRules.module.scss';
import { useTranslation } from '../../../../../../../context/LanguageContext';
import {
  selectMaxBet,
  selectMaxWin,
  selectMinBet,
} from '../../../../../../../redux/slices/configs.slices';
import { selectCurrency } from '../../../../../../../redux/slices/balance.slices';
import { useSelector } from 'react-redux';
import { selectActiveScene } from '../../../../../../../redux/slices/scene.slices';
import classNames from 'classnames';
import { SCENE_NAMES } from '../../../../../../../constants/game.constatnt';

const { GAME_SCENE } = SCENE_NAMES;

function GameRules() {
  const t = useTranslation();

  const maxWin = useSelector(selectMaxWin);
  const minBet = useSelector(selectMinBet);
  const maxBet = useSelector(selectMaxBet);
  const currency = useSelector(selectCurrency);
  const activeScene = useSelector(selectActiveScene);

  return (
    <div
      className={classNames(s.gameRulesContainer, {
        [s.active]: activeScene === GAME_SCENE,
      })}>
      <p>
        {`${t(
          'Lucky 6 is one of the types of lottery games where you can win up to',
        )} ${maxWin} ${currency}.`}
      </p>
      <p>
        {t('The principle of the game is to guess all the six numbers before the draw starts.')}
      </p>
      <p>{t('The available numerical range is 1 to 48.')}</p>
      <p>
        {t(
          'A drawing takes place in each 2 minutes during which 35 numbers out of 48 come out in sequence.',
        )}
      </p>
      <div className={s.popup__title}>{t('Conditions to have a ticket')}</div>
      <p>{t('Out of 48 numbers the players must pick 6 numbers that will form their ticket.')}</p>
      <p>{t('After picking all the 6 numbers the “place bet” button will get activated.')}</p>
      <p>{`${t(
        'The minimum bet is',
      )} ${minBet} ${currency} ${'and the maximum is'} ${maxBet} ${currency}.`}</p>
      <p>
        {t(
          'After accepting the draw the player gets a ticket for participating in the upcoming draw.',
        )}
      </p>
      <div className={s.popup__title}>{t('The draw conditions')}</div>
      <p>
        {t(
          'Out of 48 numbers, 35 come out randomly, sorted in order of exit, occupying 1 to 35 places in the table, which have their own coefficient of winning.',
        )}
      </p>
      <p>{t('If all the 6 picked numbers come out during the draw the ticket wins.')}</p>
      <div className={s.popup__title}>{t('The win coefficient')}</div>
      <p>
        {t(
          'After 35 balls go out the win amount is being decided which is based on the last match coefficient.',
        )}
      </p>
    </div>
  );
}

export default GameRules;
