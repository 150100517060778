import { MOBILE_FOOTER_TABS } from '../constants/tabs.constants';
import History from '../components/Common/Tabs/History/History.jsx';
import GameboardMobile from '../components/Mobile/GameboardMobile/GameboardMobile';
import { mobileTabsImgs } from '../assets/images/MobileTabs/mobileTabsImgs';

const { HOME, CURRENT, HISTORY } = MOBILE_FOOTER_TABS;

export const MOBILE_TABS_CONFIGS = {
  [HOME]: {
    icon: mobileTabsImgs.homeButton,
  },
  [CURRENT]: {
    icon: mobileTabsImgs.currentMobile,
    activeIcon: mobileTabsImgs.currentMobileActive,
    Component: GameboardMobile,
  },
  [HISTORY]: {
    icon: mobileTabsImgs.History,
    activeIcon: mobileTabsImgs.HistoryActive,
    Component: History,
  },
  // [JACKPOT]: {
  //   icon: mobileTabsImgs.Jackpot,
  //   activeIcon: mobileTabsImgs.JackpotActive,
  //   Component: Jackpot,
  // },
  // [LEADERBOARD]: {
  //   icon: mobileTabsImgs.Leaderboard,
  //   activeIcon: mobileTabsImgs.LeaderboardActive,
  //   Component: Leaderboard,
  // },
};
