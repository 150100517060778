import React from 'react';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HEADER_BUTTONS_CONFIGS } from '../../../../configs/headerButtons.configs';
import { useTranslation } from '../../../../context/LanguageContext';
import { selectActiveSound, setActiveSound } from '../../../../redux/slices/game.slices';
import Button from '../../UI/Button/Button';

function SoundButton({ className, button }) {
  const dispatch = useDispatch();
  const t = useTranslation();
  const isSoundActive = useSelector(selectActiveSound);

  const icon = HEADER_BUTTONS_CONFIGS[button]?.icon;
  const activeIcon = HEADER_BUTTONS_CONFIGS[button]?.activeIcon;
  const text = HEADER_BUTTONS_CONFIGS[button]?.text;
  const activeText = HEADER_BUTTONS_CONFIGS[button]?.activeText;

  return (
    <Button
      className={className}
      icon={isSoundActive ? icon : activeIcon}
      text={isSoundActive ? `${t(activeText)}` : `${t(text)}`}
      onClick={() => dispatch(setActiveSound())}
    />
  );
}

export default memo(SoundButton);
