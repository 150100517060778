import React, { memo } from 'react';
import s from './Wrapper.module.scss';
import Header from '../Header/Header';
import { useSelector } from 'react-redux';
import { selectActiveScene } from '../../../redux/slices/scene.slices';
import { GAME_SCENE_CONFIGS } from '../../../configs/gameScene.consfigs';
import { SCENE_NAMES } from '../../../constants/game.constatnt';
import { useMediaQuery } from 'react-responsive';
import HeaderMobile from '../../Mobile/HeaderMobile/HeaderMobile';
const { GAME_SCENE } = SCENE_NAMES;

const Wrapper = () => {
  const activeScene = useSelector(selectActiveScene);
  const activeSceneComponent = GAME_SCENE_CONFIGS[activeScene];

  const isMobile = useMediaQuery({
    query: '(max-width: 1020px)',
  });

  return (
    <>
      <div className={s.wrapper}>
        {activeScene === GAME_SCENE && (!isMobile ? <Header /> : <HeaderMobile />)}
        <activeSceneComponent.Component />
      </div>
    </>
  );
};

export default memo(Wrapper);
