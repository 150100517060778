import React from 'react';
import s from './HowToPlayTabs.module.scss';
import classNames from 'classnames';

function HowToPlayTabs({ tab, setActiveTab, activeTab }) {
  return (
    <div
      onClick={() => setActiveTab(tab)}
      className={classNames(s.howToPlayTabContainer, {
        [s.active]: activeTab === tab,
      })}>
      <div className={s.howToPlayTab}>{tab}</div>
    </div>
  );
}

export default HowToPlayTabs;
