import React, { useEffect, useRef } from 'react';
import s from './Progress.module.scss';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { getTimeThunk } from '../../../redux/thunks/time.thunk';
import { selectRaffleActive } from '../../../redux/slices/raffle.slices';
import { useSelector } from 'react-redux';
import { getBeforeAnimationTime, getCorrectTimeTillDraw } from '../../../helpers/game';
import { selectDuration } from '../../../redux/slices/game.slices';

const Progress = ({ className, loading }) => {
  const dispatch = useDispatch();
  const isRaffleActive = useSelector(selectRaffleActive);
  const duration = useSelector(selectDuration);
  const refLoading = useRef(null);
  const refDuration = useRef(null);
  const currentSecond = duration === 1 ? 59 : 150;

  useEffect(() => {
    (async () => {
      const atTheEndOfAnimation = {};
      atTheEndOfAnimation.promise = new Promise((resolve) => {
        atTheEndOfAnimation.resolve = resolve;
      });
      const now = Date.now();
      const {
        payload: { time },
      } = await dispatch(getTimeThunk(duration));
      const inheritanceWidth = ((currentSecond - time) / currentSecond) * 100;
      const animation = () => {
        const startAnimation = Date.now();
        const timer = startAnimation - now;
        const currentWidth =
          inheritanceWidth + (timer / 10) * (duration === 1 ? 0.01694915 : 0.006666667);
        const currentTime = time - +(timer / 1000).toFixed();
        if (refLoading.current) {
          refLoading.current.style.width = currentWidth + '%';
          refDuration.current.innerHTML = getCorrectTimeTillDraw(currentTime);
        }
        if (currentTime > currentSecond && refDuration.current) {
          refDuration.current.innerHTML = getBeforeAnimationTime(duration);
        }
        if (currentTime <= 0) {
          atTheEndOfAnimation.resolve();
        }
        if (currentTime > 0) {
          requestAnimationFrame(animation);
        }
      };
      requestAnimationFrame(animation);
      atTheEndOfAnimation.promise.then(() => {
        if (refLoading.current && refDuration.current) {
          refLoading.current.style.width = 0;
          refDuration.current.innerHTML = getBeforeAnimationTime(duration);
        }
      });
    })();
  }, [isRaffleActive, duration]);

  return (
    <div className={classNames(s.progressBarContainer, { [s.menuScene]: loading })}>
      <div className={classNames(s.progress, { [className]: className })}>
        <div ref={refLoading} className={classNames(s.progressBar, loading && s.loading)} />
      </div>
      <div ref={refDuration} className={s.progressBarSecond} />
    </div>
  );
};

export default Progress;
