import React from 'react';
import { useSelector } from 'react-redux';
import { selectBurgerMenu } from '../../../redux/slices/game.slices';
import classNames from 'classnames';
import s from './BurgerMenu.module.scss';
import BurgerMenuHeader from './BurgerMenuHeader/BurgerMenuHeader';
import { selectActiveScene } from '../../../redux/slices/scene.slices';
import { BURGER_BUTTONS, SCENE_NAMES } from '../../../constants/game.constatnt';
import { BURGER_BUTTONS_CONFIGS } from '../../../configs/BurgerButtons.configs';
import HowToPlayMobile from './HowToPlayMobile/HowToPlayMobile';

const { MENU_SCENE } = SCENE_NAMES;

function BurgerMenu() {
  const isBurgerMenuActive = useSelector(selectBurgerMenu);
  const activeScene = useSelector(selectActiveScene);

  return (
    <div
      className={classNames(s.burgerMenu, {
        [s.active]: isBurgerMenuActive,
        [s.menuScene]: activeScene === MENU_SCENE,
      })}>
      <BurgerMenuHeader />
      <div className={s.burgerMenuButtons}>
        {Object.values(BURGER_BUTTONS).map((button) => {
          const BurgerButton = BURGER_BUTTONS_CONFIGS[button];
          return <BurgerButton.Component key={button} />;
        })}
      </div>
      <HowToPlayMobile />
    </div>
  );
}

export default BurgerMenu;
