import React from 'react';
import s from './SoundButton.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveSound, setActiveSound } from '../../../../redux/slices/game.slices';
import soundOnMobile from '../../../../assets/images/header/soundOnMobile.svg';
import soundOffMobile from '../../../../assets/images/header/soundOffMobile.svg';
import { useTranslation } from '../../../../context/LanguageContext';
import classNames from 'classnames';
function SoundButton() {
  const t = useTranslation();
  const dispatch = useDispatch();
  const isSoundActive = useSelector(selectActiveSound);

  return (
    <div className={s.soundButtonContainer}>
      <div onClick={() => dispatch(setActiveSound())} className={s.soundButtonImgContainer}>
        <img
          alt="sound icon"
          className={s.soundButtonImg}
          src={isSoundActive ? soundOnMobile : soundOffMobile}
        />
      </div>
      <div className={s.soundButtonTextContainer}>
        <div
          className={classNames(s.soundButtonText, {
            [s.active]: isSoundActive,
          })}>
          {isSoundActive ? `${t('Sound On')}` : `${t('Sound Off')} `}
        </div>
      </div>
    </div>
  );
}

export default SoundButton;
