import React, { useMemo } from 'react';
import Digit from '../Digit/Digit';
import s from './DigitRow.module.scss';
import classNames from 'classnames';
import digits from '../../../../constants/digits.constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  delActiveDigit,
  resetActiveDigits,
  selectActiveDigits,
  selectActiveSound,
  setActiveDigit,
  setCheckBoxDigits,
} from '../../../../redux/slices/game.slices';
import { setErrorActive } from '../../../../redux/slices/popup.slices.js';
import { useSoundConstext } from '../../../../context/SoundContext';
import { useTranslation } from '../../../../context/LanguageContext.js';

function DigitRow({ rowColor }) {
  const t = useTranslation();
  const dispatch = useDispatch();
  const useSoundData = useSoundConstext();
  const isSoundActive = useSelector(selectActiveSound);
  const activeDigits = useSelector(selectActiveDigits);

  let arr = useMemo(() => {
    const results = {
      value: [],
    };
    for (let i in digits) {
      if (digits[i] === rowColor) {
        results.value.push(Number(i));
      }
    }
    return results;
  }, []);

  const isAllChecked = useMemo(
    () => arr.value.every((item) => activeDigits.includes(item)),
    [activeDigits],
  );

  const onDigitClick = (digit) => {
    if (activeDigits.includes(digit)) {
      dispatch(delActiveDigit(digit));
    } else if (activeDigits.length < 6) {
      dispatch(setActiveDigit(digit));
    } else if (activeDigits.length === 6) {
      dispatch(setErrorActive(t('The ticket cannot contain more than 6 numbers.')));
    }
    isSoundActive && useSoundData.click();
  };

  const onCheckBoxClick = (digits) => {
    if (isAllChecked) {
      dispatch(setCheckBoxDigits([]));
    } else {
      dispatch(setCheckBoxDigits(digits));
    }
    isSoundActive && useSoundData.click();
  };

  return (
    <div className={s.rowContainer}>
      <div className={s.digitRow}>
        {arr.value.map((digit) => {
          return (
            <div
              key={digit}
              onClick={() => onDigitClick(Number(digit))}
              className={classNames(s.digitRow__num, {
                [s.active]: activeDigits && activeDigits.includes(Number(digit)),
              })}>
              <Digit className={s.digit} key={digit} digit={digit} />
            </div>
          );
        })}
      </div>
      <div className={s.rowCheckBox}>
        <label className={classNames(s.rowCheckBox__label, s[rowColor.toLowerCase()])}>
          <input
            checked={isAllChecked}
            type="checkbox"
            className={s.rowCheckBox__input}
            onChange={() => onCheckBoxClick(arr.value)}
          />
          <span className={classNames(s.rowCheckBox__check, s[rowColor.toLowerCase()])} />
        </label>
      </div>
    </div>
  );
}

export default DigitRow;
