import React, { memo } from 'react';
import DigitsBoard from '../DigitsBoard/DigitsBoard';
import s from './Gameboard.module.scss';
import Ticket from '../Ticket/Ticket';
import GamePlay from '../../Desktop/GamePlay/GamePlay';
import { useMediaQuery } from 'react-responsive';
import Footer from '../../Mobile/Footer/Footer';
import { useSelector } from 'react-redux';
import { selectMobileActiveTab } from '../../../redux/slices/game.slices';
import { selectUserCurrentHistory } from '../../../redux/slices/userCurrentHistory.slices';
import { selectRaffleActive } from '../../../redux/slices/raffle.slices';
import { selectUserHistoryAddedTickets } from '../../../redux/slices/userHistory.slices';
import { selectDrawId } from '../../../redux/slices/drawHistory.slices';
import { selectRaceId } from '../../../redux/slices/time.slices';
import { MOBILE_FOOTER_TABS } from '../../../constants/tabs.constants';
import GameboardMobile from '../../Mobile/GameboardMobile/GameboardMobile';
import { useTranslation } from '../../../context/LanguageContext';
import { MOBILE_TABS_CONFIGS } from '../../../configs/mobileTabs.configs';

const { CURRENT } = MOBILE_FOOTER_TABS;

function Gameboard() {
  const t = useTranslation();
  const userCurrentHistory = useSelector(selectUserCurrentHistory);
  const userHistoryAddedTickets = useSelector(selectUserHistoryAddedTickets);
  const drawId = useSelector(selectDrawId);
  const raceId = useSelector(selectRaceId);
  const addedTickets = [...userCurrentHistory];
  const countedTickets =
    userHistoryAddedTickets && userHistoryAddedTickets.filter((ticket) => ticket.draw == drawId);

  const mobileActiveTab = useSelector(selectMobileActiveTab);
  const isRaffleActive = useSelector(selectRaffleActive);
  const isMobile = useMediaQuery({
    query: '(max-width: 1020px)',
  });

  const isCurrentTabActive = mobileActiveTab == CURRENT;

  const activeMobileTabConmponent = isMobile && MOBILE_TABS_CONFIGS[mobileActiveTab];

  return (
    <div className={s.gameboard}>
      {isMobile ? (
        <>
          <GameboardMobile isCurrentTabActive={isCurrentTabActive} />
          {!isCurrentTabActive && <activeMobileTabConmponent.Component />}
          <Footer />
        </>
      ) : (
        <>
          <GamePlay />
          <DigitsBoard />
          <div className={s.gameboard__tickets}>
            <Ticket />
            {(isRaffleActive ? countedTickets : addedTickets).map((ticket, index) => {
              return (
                <Ticket
                  key={index}
                  ticket={ticket}
                  index={index}
                  addedTicket
                  isRaffleActive={isRaffleActive}
                />
              );
            })}
            {isRaffleActive && <div className={s.nextDrawLine} />}
            {isRaffleActive && <div className={s.nextDrawText}>{t(`Next draw's tickets`)}</div>}
            {isRaffleActive &&
              addedTickets
                .filter((ticket) => ticket.draw == raceId)
                .map((ticket, index) => (
                  <Ticket key={index} index={index} ticket={ticket} addedTicket nextAddedTicket />
                ))}
          </div>
        </>
      )}
    </div>
  );
}

export default memo(Gameboard);
