import React, { useState } from 'react';
import s from './HowToPlayMobile.module.scss';
import { HOWTOPLAY_TABS } from '../../../../constants/game.constatnt';
import HowToPlayTabs from './HowToPlayTabs/HowToPlayTabs';
import { HOWTOPLAY_TABS_CONFIGS } from '../../../../configs/howToPlay.configs';

const { GAME_RULES } = HOWTOPLAY_TABS;

function HowToPlayMobile() {
  const [activeTab, setActiveTab] = useState(GAME_RULES);

  const tabComponent = HOWTOPLAY_TABS_CONFIGS[activeTab];

  return (
    <div className={s.howToPlayMobileSection}>
      <div className={s.howToPlayMobileTabs}>
        {Object.values(HOWTOPLAY_TABS).map((tab) => {
          return (
            <HowToPlayTabs key={tab} tab={tab} setActiveTab={setActiveTab} activeTab={activeTab} />
          );
        })}
      </div>
      <tabComponent.Component />
    </div>
  );
}

export default HowToPlayMobile;
