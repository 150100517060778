import { createSlice } from '@reduxjs/toolkit';
import { POPUP_TYPES, betErrorText } from '../../constants/game.constatnt';

const { HOWTOPLAY, ERROR, SESSION_ERROR } = POPUP_TYPES;

const initialState = {
  popupType: false,
  errorMassage: '',
  sessionErrorMassage: '',
};

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    setHowToPlayActive: (state) => {
      state.popupType = HOWTOPLAY;
    },
    setErrorActive: (state, { payload }) => {
      state.popupType = ERROR;
      state.errorMassage = payload;
    },
    setBetError: (state) => {
      state.popupType = ERROR;
      state.errorMassage = betErrorText;
    },
    setSessionErrorActive: (state, { payload }) => {
      state.popupType = SESSION_ERROR;
      state.sessionErrorMassage = payload;
    },
    setPopupsDisable: (state) => {
      state.popupType = false;
    },
  },
});

// ACTIONS
export const {
  setHowToPlayActive,
  setErrorActive,
  setPopupsDisable,
  setSessionErrorActive,
  setBetError,
} = popupSlice.actions;

// SELECTORS
export const selectActivePopup = (state) => state.popup.popupType;
export const selectErrorMassage = (state) => state.popup.errorMassage;
export const selectSessionErrorMassage = (state) => state.popup.sessionErrorMassage;

export default popupSlice.reducer;
