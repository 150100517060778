import React from 'react';
import s from './BetAmountOptions.module.scss';
import Button from '../../../Common/UI/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { selectAmount, setAmount, setAmountOptiosn } from '../../../../redux/slices/game.slices';
import { selectAmountRanges, selectMaxBet } from '../../../../redux/slices/configs.slices';
import { setErrorActive } from '../../../../redux/slices/popup.slices';
import { selectCurrency } from '../../../../redux/slices/balance.slices';
import { useTranslation } from '../../../../context/LanguageContext';
import classNames from 'classnames';

function BetAmountOptions({ denomination }) {
  const dispatch = useDispatch();
  const t = useTranslation();
  const maxBet = useSelector(selectMaxBet);
  const currency = useSelector(selectCurrency);
  const selectedAmount = useSelector(selectAmount);
  const amountRanges = useSelector(selectAmountRanges);

  const clickHandler = (amount) => {
    const isLessMaxbet = amount + +selectedAmount <= +maxBet;

    const setMaxBet = () => {
      dispatch(setAmount(maxBet));
      dispatch(
        setErrorActive(
          `${t('Maximum bet is')} ${maxBet} ${currency}. ${t(
            'Please, enter an amount within the allowed limit.',
          )}`,
        ),
      );
    };

    isLessMaxbet ? dispatch(setAmountOptiosn(amount)) : setMaxBet();
  };

  return (
    <div className={s.amount__fastBet}>
      {!!amountRanges &&
        amountRanges.map((item) => {
          return (
            <div
              key={item}
              className={classNames(s.amount__bet, {
                [s.threeAmounts]: amountRanges.length === 3,
              })}>
              <Button
                className={s.amount__button}
                amountText={`+${item}`}
                onClick={() => clickHandler(item)}
              />
            </div>
          );
        })}
    </div>
  );
}

export default BetAmountOptions;
