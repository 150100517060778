import React from 'react';
import s from './BurgerMenuHeader.module.scss';
import { setBurgerMenu } from '../../../../redux/slices/game.slices';
import crossIcon from '../../../../assets/images/header/Cross_Icon.png';
import { useDispatch } from 'react-redux';
import luckyBurgerLogo from '../../../../assets/images/header/luckyBurgerLogo.png';

function BurgerMenuHeader() {
  const dispatch = useDispatch();

  return (
    <div className={s.burgerMenuImgContainer}>
      <div onClick={() => dispatch(setBurgerMenu())} className={s.crossIconContainer}>
        <img alt="cross icon" className={s.crossIcon} src={crossIcon} />
      </div>
      <div className={s.luckyBurgerLogoContainer}>
        <img alt="burger logo" src={luckyBurgerLogo} className={s.luckyBurgerLogo} />
      </div>
    </div>
  );
}

export default BurgerMenuHeader;
