import React from 'react';
import nrgLogo from '../../../../assets/images/Logo/nrgaming.svg';
import s from './StartPageFotter.module.scss';

function StartPageFotter() {
  return (
    <div className={s.startPage__footer}>
      <div className={s.startPageCompany}>
        <img src={nrgLogo} alt="NRGaming" className={s.startPageCompany__img} />
      </div>
    </div>
  );
}

export default StartPageFotter;
