import SoundButton from '../components/Mobile/BurgerMenu/BurgerMenuButtons/SoundButton';
import { BURGER_BUTTONS } from '../constants/game.constatnt';

const { SOUND_BUTTON } = BURGER_BUTTONS;

export const BURGER_BUTTONS_CONFIGS = {
  [SOUND_BUTTON]: {
    Component: SoundButton,
  },
};
