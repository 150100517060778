import React from 'react';
import s from './CoefficientTableSection.module.scss';
import { useTranslation } from '../../../../../../../context/LanguageContext';
import { useSelector } from 'react-redux';
import { selectCurrency } from '../../../../../../../redux/slices/balance.slices';
import CoefficientTable from '../../../../../../Common/Popup/HowToPlay/CoefficienTable/CoefficientTable';
import { ALL_WINING_PLACES } from '../../../../../../../configs/game.configs';
import classNames from 'classnames';
import { selectActiveScene } from '../../../../../../../redux/slices/scene.slices';
import { SCENE_NAMES } from '../../../../../../../constants/game.constatnt';

const firstTablePlaces = ALL_WINING_PLACES.slice(0, 15);
const secondTablePlaces = ALL_WINING_PLACES.slice(15);

const { GAME_SCENE } = SCENE_NAMES;

function CoefficientTableSection() {
  const t = useTranslation();
  const currency = useSelector(selectCurrency);
  const activeScene = useSelector(selectActiveScene);

  return (
    <div
      className={classNames(s.coefficientTableContainer, {
        [s.active]: activeScene === GAME_SCENE,
      })}>
      <div className={s.popup__title}>{t('The coefficient table')}</div>
      <p>
        {t(
          'The 1 to 5 range does not have any coefficients as the ticket wins only if it has 6 matches.',
        )}
      </p>
      <div className={s.popup__tables}>
        <CoefficientTable places={firstTablePlaces} />
        <CoefficientTable places={secondTablePlaces} />
      </div>
      <div style={{ display: 'flex' }}>
        <p>{t('Example')}:&nbsp;</p>
        <div>
          <p>{t('the ticket includes these numbers - 5 18 40 26 10 7')}</p>
          <p>{`${t('bet amount - 150')} ${currency}`}</p>
          <p>{t('exit order - 10 14 25 5 13 18 7 9 26 40․․․․․․')}</p>
        </div>
      </div>
      <p>
        {t(
          'the full match of the ticket happened on the 10th place: The coefficient for the 10th place is 1000:',
        )}
      </p>
      <p>{t('The total win amount for this ticket is:')}</p>
      <p>{`${t('150(bet) * 1000(coefficient for 10th number) = 150.000')} ${currency}`}</p>
    </div>
  );
}

export default CoefficientTableSection;
