import React from 'react';
import s from './HeaderMobile.module.scss';
import UserInfo from './UserInfo/UserInfo';
import burgerIcon from '../../../assets/images/header/Burger_ICON.png';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveScene } from '../../../redux/slices/scene.slices';
import Progress from '../../Common/Progress/Progress';
import { useMediaQuery } from 'react-responsive';
import { SCENE_NAMES } from '../../../constants/game.constatnt';
import { setBurgerMenu } from '../../../redux/slices/game.slices';

const { GAME_SCENE } = SCENE_NAMES;

function HeaderMobile() {
  const dispatch = useDispatch();
  const activeScene = useSelector(selectActiveScene);

  const isMobile = useMediaQuery({
    query: '(max-width: 1020px)',
  });

  return (
    <header className={s.headerMobile}>
      <div className={s.headerMobileContainer}>
        <div className={s.headerMobileInfoContainer}>
          <UserInfo />
        </div>
        <div onClick={() => dispatch(setBurgerMenu(true))} className={s.burgerIconContainer}>
          <img alt="burger icon" src={burgerIcon} className={s.burgerIcon} />
        </div>
      </div>
      {activeScene == GAME_SCENE && isMobile && <Progress className={s.progress} />}
    </header>
  );
}

export default HeaderMobile;
