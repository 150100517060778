import React from 'react';
import s from './FooterButton.module.scss';
import Button from '../../../Common/UI/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { selectMobileActiveTab, setMobileActiveTab } from '../../../../redux/slices/game.slices';
import { MOBILE_TABS_CONFIGS } from '../../../../configs/mobileTabs.configs';
import { setGameScene, setProcessScene } from '../../../../redux/slices/scene.slices';
import {
  resetPulledDigit,
  setBigDigit,
  setRaffleActive,
} from '../../../../redux/slices/raffle.slices';
import { PROCESS_SCENE, SCENE_NAMES } from '../../../../constants/game.constatnt';

const { MENU_SCENE } = SCENE_NAMES;
const { WAITING_SCENE } = PROCESS_SCENE;

function FooterButton({ tab }) {
  const dispatch = useDispatch();
  const mobileActiveTab = useSelector(selectMobileActiveTab);

  const onHomeButtonClick = () => {
    dispatch(setGameScene(MENU_SCENE));
    dispatch(setBigDigit(null));
    dispatch(resetPulledDigit());
    dispatch(setRaffleActive(false));
    dispatch(setProcessScene(WAITING_SCENE));
  };

  const onTabClick = (tab) => {
    if (tab === 'Home') {
      onHomeButtonClick();
    } else {
      dispatch(setMobileActiveTab(tab));
    }
  };

  return (
    <Button
      mobileTabs
      className={s.footer__button}
      icon={
        mobileActiveTab == tab ? MOBILE_TABS_CONFIGS[tab].activeIcon : MOBILE_TABS_CONFIGS[tab].icon
      }
      activeTab={mobileActiveTab}
      onClick={() => onTabClick(tab)}
    />
  );
}

export default FooterButton;
