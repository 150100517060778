import React from 'react';
import s from './Footer.module.scss';
import FooterButton from './FooterButton/FooterButton';
import { MOBILE_TABS_CONFIGS } from '../../../configs/mobileTabs.configs';

const gameTabs = Object.keys(MOBILE_TABS_CONFIGS);

const Footer = () => {
  return (
    <footer className={s.footer}>
      <ul className={s.footer__list}>
        {gameTabs.map((tab) => (
          <li className={s.footer__item} key={tab}>
            <FooterButton tab={tab} />
          </li>
        ))}
      </ul>
    </footer>
  );
};

export default Footer;
